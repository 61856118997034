import React from 'react'
import Layout from '~global/Layout'
import Header from '~components/Header'
import Footer from '~components/Footer'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import '../styles/pages/404.scss'

const NotFoundPage = () => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  return (
    <Layout>
      <div className="not-found">
        <Header />
        <div className="not-found__content" ref={componentRef}>
          <img className="not-found__image" src="/static/huge-404.svg" alt="404" />
          <p>Page not found</p>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

export default NotFoundPage
